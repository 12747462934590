/* eslint-disable import/named */
import { FirebaseApp, initializeApp } from 'firebase/app';

let firebaseApp: FirebaseApp;

const initFirebase = (): FirebaseApp => {
  if (!firebaseApp) {
    firebaseApp = initializeApp({
      apiKey: import.meta.env.VITE_FB_API_KEY,
      authDomain: import.meta.env.VITE_AUTH_DOMAIN,
      projectId: import.meta.env.VITE_PROJECT_ID,
      storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
      messagingSenderId: import.meta.env.VITE_MESSAGING_SENDER_ID,
      appId: import.meta.env.VITE_FB_APP_ID,
      measurementId: import.meta.env.VITE_MEASUREMENT_ID,
    });
  }

  return firebaseApp;
};

export default initFirebase;
